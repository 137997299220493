import areaCode from './areaCode'
/**
 * @desc define constants
 */
export const SET_VM = 'setVm'
export const SET_IS_PC = 'setIsPc'
export const PAGE_SIZE = 10
export const H5_PAGE_SIZE = 20
export const SET_H5_SHOW_MENU = 'h5ShowMenu'
export const SET_LOGIN_LOADING = 'setLoginLoading'
export const SET_HEAD_INFORMATION = 'setHeadInformation'

export const REGULAR_EMAIL = /^[\w._]+@[\S]+\.([a-z]{2,6}|[\u2E80-\u9FFF]{2,3})$/
export const AREA_CODE = areaCode

// about Mobile device
export const ANDROID = 'android'
export const IOS = 'ios'

//about terminal
export const TERMINAL_WEB = 'WEB'
export const TERMINAL_LARK = 'LARK'
export const TERMINAL_MV = 'MV'

//about Currency
export const BRL = 'R$'
export const MXN = 'MXN$'
export const USD = 'US$'
export const COP = 'COP$'
export const CLP = 'CLP$'
export const EUR = 'EU€'
export const PEN = 'PEN'
export const CURRENCY = {
  BRL: 'BRL',
  MXN: 'MXN',
  USD: 'USD',
  COP: 'COP',
  CLP: 'CLP',
  EUR: 'EUR',
  PEN: 'PEN'
}
export const EXCHANGE_TARGET_CURRENCYS = [CURRENCY.USD, CURRENCY.EUR]

// about language
export const SET_LANG = 'setLang'
export const LOCAL_LANG = 'localLang'
export const EN = 'en'
export const ZH = 'zh-CN'

// about permission
export const SET_BTNS = 'setBtns'
export const MY_TOKEN = 'lqdMyToken'
export const SET_PERMISSION = 'setPermission'
export const CLEAR_PERMISSION = 'clearPermission'
export const SET_MENU = 'setMenu'
export const CLEAR_MENU = 'clearMenu'
export const SET_USER = 'setUser'
export const CLEAR_USER = 'clearUser'
export const SET_DEFAULT_MENU = 'setDefaultMenu'
export const CLEAR_DEFAULT_MENU = 'clearDefaultMenu'
export const CURRENT_MENU = 'lqdCurrentMenu'
export const CURRENT_USER = 'lqdCurrentUser'
export const SET_MERCHANT = 'setMerchant'
export const SET_ACCOUNTS = 'setAccounts'

// abount transactions
export const PAY_IN = 'PAY_IN'
export const PAY_OUT = 'PAY_OUT'
export const MARKET_PLACE_ORDERS = 'MARKET_PLACE_ORDERS'
export const REFUND = 'REFUND'

// abount transaction status
export const STATUS_INITIAL_STATUS = 'INITIAL_STATUS'
export const STATUS_IN_PROGRESS = 'IN_PROGRESS'
export const STATUS_SETTLED = 'SETTLED'
export const STATUS_FAILED = 'FAILED'
export const STATUS_CHARGED_BACK = 'CHARGED_BACK'
export const STATUS_REFUNDING = 'REFUNDING'
export const STATUS_REFUNDED = 'REFUNDED'
export const STATUS_EXPIRED = 'EXPIRED'
export const STATUS_REJECTED = 'REJECTED'
export const STATUS_CREATED = 'CREATED'
export const STATUS_CANCELLED = 'CANCELLED'

// abount product
export const SPEI = 'SPEI'
export const SPEI_VA = 'SPEI_VA'
export const SPEI_BANK_TRANSFER = 'SPEI_BANK_TRANSFER'
export const TED = 'TED'
export const PIX = 'PIX'
export const CARD = 'CARD'
export const BOLETO = 'BOLETO'
export const OXXO = 'OXXO'
export const GIFTCARD = 'GIFTCARD'
export const TOPUP = 'TOPUP'
export const UTILITY = 'UTILITY'
export const PAY_CASH = 'PAY_CASH'
export const PIC_PAY = 'PIC_PAY'
export const PAYPAL = 'PAYPAL'
export const MERCADO_PAGO = 'MERCADO_PAGO'
export const AME_QR = 'AME_QR'
export const CO_BANK_TRANSFER = 'CO_BANK_TRANSFER'
export const CL_BANK_TRANSFER = 'CL_BANK_TRANSFER'
export const PSE = 'PSE'
export const NEQUI = 'NEQUI'
export const BANCOLOMBIA_BUTTON = 'BANCOLOMBIA_BUTTON'
export const EFECTY = 'EFECTY'
export const BANCOLOMBIA_COLLECTION = 'BANCOLOMBIA_COLLECTION'
export const PIX_QR_CODE = 'PIX_QR_CODE'
export const MULTICAJA_TRANSFER = 'MULTICAJA_TRANSFER'
export const MULTICAJA_CASH = 'MULTICAJA_CASH'
export const HITES = 'HITES'
export const FPAY = 'FPAY'
export const CHEK = 'CHEK'
export const MACH = 'MACH'
export const PAGO46 = 'PAGO46'
export const KHIPU = 'KHIPU'
export const WEB_PAY = 'WEB_PAY'

// Business type
export const BUSINESS_TRANSACTION = 'TRANSACTION'
export const BUSINESS_TOPUP = 'TOPUP'
export const BUSINESS_REFUND = 'REFUND'
export const BUSINESS_TRANSFER_OUT = 'TRANSFER_OUT'
export const BUSINESS_EXCHANGE = 'EXCHANGE'
export const BUSINESS_ADJUSTMENT = 'ADJUSTMENT'
export const BUSINESS_SUPPLEMENT = 'SUPPLEMENT'

// Direction type
export const DIRECTION_SETTLED = 'SETTLED'
export const DIRECTION_REFUND = 'REFUND'
export const DIRECTION_REJECTED = 'REJECTED'
export const DIRECTION_CHARGE_BACK = 'CHARGE_BACK'
export const DIRECTION_CHARGE_BACK_REJECTED = 'CHARGE_BACK_REJECTED'

// Message type
export const MESSAGE_LOGIN = 'loginMessage'

// about login status
export const LOGIN_SUCCESS = 'loginSuccess'
export const LOGIN_FAIL = 'loginFail'

// reset password scene
export const RESET_PSW_SCENE = {
  NORMAL: 'NORMAL',
  INITIAL: 'INITIAL',
  EXPIRED: 'EXPIRED',
  ABOUT2EXPIRE: 'ABOUT2EXPIRE'
}

// about payment
export const PIX_TYPE_DOCUMENT = 'DOCUMENT'
export const PIX_TYPE_PHONE = 'PHONE'
export const PIX_TYPE_EMAIL = 'EMAIL'
export const PIX_TYPE_RANDOM = 'RANDOM'
export const APPROVE_STATUS_PENDING = 'PENDING'
export const APPROVE_STATUS_APPROVING = 'APPROVING'
export const APPROVE_STATUS_APPROVED = 'APPROVED'
export const APPROVE_STATUS_REJECTED = 'REJECTED'

// about W/O
export const WO_TYPE_TRANSACTIONS = 'TRANSACTIONS'
export const WO_TYPE_FINANCE = 'FINANCE'
export const WO_TYPE_BALANCE = 'BALANCE'
export const WO_TYPE_OTHERS = 'OTHERS'

export const WO_STATUS_PENDING = 'PENDING'
export const WO_STATUS_PROCESSING = 'PROCESSING'
export const WO_STATUS_SOLVED = 'SOLVED'
export const WO_STATUS_CLOSED = 'CLOSED'

// about transaction case
export const TRANSACTION_WO_STATUS_PENDING = 'PENDING'
export const TRANSACTION_WO_STATUS_PROCESSING = 'PROCESSING'
export const TRANSACTION_WO_STATUS_SOLVED = 'SOLVED'

// abount country
export const CHANGE_COUNTRY = 'changeCountry'
export const US = 'US'
export const BR = 'BR'
export const MX = 'MX'
export const CO = 'CO'
export const CL = 'CL'
export const PE = 'PE'

// abount timezone
export const SET_TIMEZONE = 'setTimezone'

// about Payout Funding
export const TOPUP_STATUS_PROCESSING = 'PROCESSING'
export const TOPUP_STATUS_COMPLETED = 'COMPLETED'
export const TOPUP_STATUS_REJECTED = 'REJECTED'
export const TOPUP_STATUS_CANCELED = 'CANCELED'
export const TOPUP_STATUS_FAILED = 'FAILED'

// about Withdrawal
export const TRANSFER_OUT_STATUS_PROCESSING = 'PROCESSING'
export const TRANSFER_OUT_STATUS_COMPLETED = 'COMPLETED'
export const TRANSFER_OUT_STATUS_IN_TRANSFER = 'IN_TRANSFER'
export const TRANSFER_OUT_STATUS_REJECTED = 'REJECTED'
export const TRANSFER_OUT_STATUS_CANCELED = 'CANCELED'
export const TRANSFER_OUT_STATUS_PAYMENT_FAILED = 'PAYMENT_FAILED'
export const TRANSFER_OUT_STATUS_FAILED = 'FAILED'

// about exchange
export const EXCHANGE_STATUS_WAIT_PROOF = 'WAIT_PROOF'
export const EXCHANGE_STATUS_WAIT_EXCHANGE_RATE = 'WAIT_EXCHANGE_RATE'
export const EXCHANGE_STATUS_WAIT_CONFIRM_CONTRACT = 'WAIT_CONFIRM_CONTRACT'
export const EXCHANGE_STATUS_PROCESSING = 'PROCESSING'
export const EXCHANGE_STATUS_COMPLETED = 'COMPLETED'
export const EXCHANGE_STATUS_REJECTED = 'REJECTED'
export const EXCHANGE_STATUS_CANCELED = 'CANCELED'
export const EXCHANGE_STATUS_FAILED = 'FAILED'

export const EXCHANGE_TYPE_PAY_IN = 'PAY_IN'
export const EXCHANGE_TYPE_PAY_OUT = 'PAY_OUT'
export const EXCHANGE_TYPE_OFFLINE = 'OFFLINE'

// about SubMerchant
export const SUB_ACCOUNT_PROCESSING = 'PROCESSING'
export const SUB_ACCOUNT_COMPLETED = 'COMPLETED'
export const SUB_ACCOUNT_REJECTED = 'REJECTED'
export const SUB_ACCOUNT_CANCELED = 'CANCELED'
export const SUB_ACCOUNT_FAILED = 'FAILED'

// about payment
export const NATIONAL_PRODUCTS = {
  [BR]: {
    currency: BRL,
    option: {
      [PIX]: {
        minAmount: 1,
        label: 'PIX'
      },
      [TED]: {
        minAmount: 1,
        label: 'Bank Transfer'
      }
    }
  },
  [MX]: {
    currency: MXN,
    option: {
      [SPEI]: {
        minAmount: 1,
        label: 'SPEI'
      }
    }
  },
  [CL]: {
    currency: CLP,
    option: {
      [CL_BANK_TRANSFER]: {
        minAmount: 1000,
        label: 'Bank Transfer'
      }
    }
  },
  [CO]: {
    currency: COP,
    option: {
      [CO_BANK_TRANSFER]: {
        minAmount: 1,
        label: 'Bank Transfer'
      }
    }
  }
}

// about payout
export const PAYOUT_IN_PROGRESS = 'IN_PROGRESS'
export const PAYOUT_SETTLED = 'SETTLED'
export const PAYOUT_REJECTED = 'REJECTED'
export const PAYOUT_FAILED = 'FAILED'

// empty text
export const EMPTY_TEXT = '--'

// fee config
export const FEE_ON_MAP = {
  AMOUNT: 'AMOUNT',
  FEE: 'FEE'
}
export const FEE_TYPE_MAP = {
  commissionFees: 'commissionFees',
  taxFees: 'taxFees',
  fxFees: 'fxFees',
  refundFees: 'refundFees',
  chargebackFees: 'chargebackFees'
}

export const ENABLE = 'ENABLE'
export const DISABLE = 'DISABLE'
export const SWITCH_OPTION = {
  1: 'Enable',
  0: 'Disable'
}

// cent as unit
export const MIN_VALUE = 0
export const MAX_MONTHLY_VOLUME = 99999999999999999999999999
export const MAX_VOLUME = 99999999999999999999999999
export const MIN_FEE_AMOUNT = 0
export const MAX_FEE_AMOUNT = 99999999999999999999999999

// 10M
export const MAX_FILE_SIZE = 10485760

export const SET_VENDOR_LIST = 'setVendorList'

// Message center
export const MESSAGE_TYPE_EMAIL = 'EMAIL'
export const MESSAGE_TYPE_SMS = 'SMS'
export const MESSAGE_TYPE_SYSTEM = 'SYSTEM'
export const DELIVERY_TYPES = {
  [MESSAGE_TYPE_EMAIL]: MESSAGE_TYPE_EMAIL,
  [MESSAGE_TYPE_SMS]: MESSAGE_TYPE_SMS,
  [MESSAGE_TYPE_SYSTEM]: MESSAGE_TYPE_SYSTEM
}

export const SYSTEM_MESSAGE_TYPE_ANNOUNCEMENT = '1'
export const SYSTEM_MESSAGE_TYPE_NOTICE = '2'
export const SYSTEM_MESSAGE_TYPES = {
  [SYSTEM_MESSAGE_TYPE_ANNOUNCEMENT]: 'ANNOUNCEMENT',
  [SYSTEM_MESSAGE_TYPE_NOTICE]: 'NOTICE'
}

export const EMAIL_CONTENT_TYPE_TEXT = '1'
export const EMAIL_CONTENT_TYPE_HTML = '2'
export const EMAIL_CONTENT_TYPES = {
  [EMAIL_CONTENT_TYPE_TEXT]: 'TEXT',
  [EMAIL_CONTENT_TYPE_HTML]: 'HTML'
}
